import React from 'react';
import styled from 'react-emotion';
import { Link,graphql, useStaticQuery } from 'gatsby';

import 'typeface-open-sans';
import Slider from "react-slick";
import './home.css'
const JumbotronContainer = styled.div`
  ${tw`flex items-center justify-center`}
  height: 190px;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  position:relative;
  @media (min-width:640px){
    height: 295px
  }
  @media (min-width:768px){
    height: 450px
  }
  @media (min-width:1025px){
    height: 650px
  }
`
const BannerLink = styled.a`
  width:100%;
  height:100%;
`

const JumbotronContent = styled.div`
  ${tw`py-5 md:py-10 px-4 md:px-8 lg:px-12 lg:w-5/6`};
  z-index:1;
  
`
const Column = styled.div`
  ${tw`w-2/5 px-0`};
`
const DescriptionColumn = styled(Column)`
  ${tw`w-3/5 text-white text-sm sm:text-2xl md:text-3xl lg:text-4xl flex flex-col justify-center`};
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: Open Sans;
  line-height: 1.5;
  vertical-align: middle;
`
const Flexbox = styled.div`
  ${tw`flex justify-center`}
`
const ButtonFlexbox = styled(Flexbox)`
  ${tw`justify-around mt-3`}
`
const ModemImage = styled.img`
  ${tw`m-0`};
`
const Button = styled.a`
  ${tw`bg-red text-yellow rounded-full font-bold px-4 py-1 text-xs sm:text-sm md:text-base xl:text-xl xl:px-8 xl:py-2 hover:text-white`}
  text-decoration: none;
`
const OrderButton = styled(Link)`
  ${tw`bg-yellow text-red rounded-full px-4 font-bold py-1 text-xs sm:text-sm md:text-base xl:text-xl xl:px-8 xl:py-2 hover:text-white`}
  text-decoration: none;
`
const JumboHead = styled.h1`
  ${tw`text-sm text-white uppercase mb-1 leading-tight sm:text-base md:text-xl lg:text-3xl xl:text-4xl`}
  font-family : Open Sans
`
const JumboSub = styled.h3`
  ${tw`text-xs text-white mb-1 font-normal sm:text-sm md:text-base lg:text-lg xl:mb-4`}
  font-family: Open Sans
`
const SVGIcon = styled.svg`
  ${tw`fill-current h-5 w-5 md:h-10 md:w-10`};
`
const ArrowBtn = styled.button`
  color: #fff;
  z-index:1;
  &:before{
    content: initial
  };
  &:hover,&:focus{
    color:#eee
  }
`
const LeftArrow = styled(ArrowBtn)`
  left:0;
`
const RightArrow = styled(ArrowBtn)`
  right:0;
  @media (min-width:768px){
    right: 1.25rem
  }
`
const ArrowSlide = ({className,to,onClick})=>(
  to=='left' ? 
    <LeftArrow type="button" onClick={onClick} className={`arrow-btn ${className}`} aria-label={to}>
      <SVGIcon viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M 55.921875 9.867188 L 62.5 9.867188 C 63.773438 9.867188 64.929688 10.601563 65.46875 11.746094 C 66.019531 12.902344 65.855469 14.261719 65.042969 15.238281 L 37.148438 49.34375 L 65.042969 83.445313 C 65.855469 84.421875 66.019531 85.785156 65.46875 86.941406 C 64.929688 88.082031 63.773438 88.816406 62.5 88.816406 L 55.921875 88.816406 C 54.933594 88.816406 53.992188 88.367188 53.375 87.609375 L 23.773438 51.421875 C 22.78125 50.214844 22.78125 48.46875 23.773438 47.261719 L 53.375 11.078125 C 53.992188 10.316406 54.933594 9.867188 55.921875 9.867188 Z "/>
      </SVGIcon>
    </LeftArrow> : 
    <RightArrow type="button" onClick={onClick} className={`arrow-btn ${className}`} aria-label={to}>
      <SVGIcon viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M 39.472656 88.816406 L 32.894531 88.816406 C 31.621094 88.816406 30.464844 88.082031 29.925781 86.941406 C 29.375 85.785156 29.542969 84.421875 30.351563 83.445313 L 58.246094 49.34375 L 30.351563 15.238281 C 29.542969 14.261719 29.375 12.902344 29.925781 11.746094 C 30.464844 10.601563 31.621094 9.867188 32.894531 9.867188 L 39.472656 9.867188 C 40.464844 9.867188 41.402344 10.316406 42.019531 11.078125 L 71.625 47.261719 C 72.613281 48.46875 72.613281 50.214844 71.625 51.421875 L 42.019531 87.609375 C 41.402344 88.367188 40.464844 88.816406 39.472656 88.816406 Z "/>
      </SVGIcon>
    </RightArrow>
)
const ImgBox = styled.a`
  ${tw`w-12 h-12 bg-red`};
  border: solid 1px #FCEB19;
  background-size:cover;
  @media (min-width:640px){
    width:5rem;height:5rem;
  };
  @media (min-width:768px){
    width:7rem;height:7rem;border-width:2px;margin-top:1rem
  }
  @media (min-width:1200px){
    width: 10rem;height:10rem
  }
`
const ImgBox2 = styled.a`
  ${tw`bg-red mx-2 mt-3`};
  width:3.5rem;
  height:3.5rem;
  background-size:cover;
  @media (min-width:640px){
    width:6rem;height:6rem;
  }
  @media (min-width:768px){
    width:8rem;height:8rem;margin-top:2rem
  }
  @media (min-width:1200px){
    width:13rem;height:13rem
  }
`
const ImgBoxText = styled.span`
  ${tw`mb-0 text-white font-semibold leading-tight uppercase pt-1`}
  font-size: .35rem;
  @media (min-width:640px){
    font-size: .65rem
  };
  @media (min-width:768px){
    font-size: .85rem
  }
  @media (min-width:1200px){
    font-size: 1rem
  }
`
const ImgBoxText2 = styled.span`
  ${tw`mb-0 text-white font-semibold leading-tight uppercase pt-1`}
  font-size: .5rem;
  @media (min-width:640px){
    font-size: .65rem
  }
  @media (min-width:768px){
    font-size: .81rem
  }
  @media (min-width:1200px){
    font-size: 1.125rem
  }
`
const ImgBoxHead = styled(ImgBoxText)`
  font-size:.65rem;
  @media (min-width:640px){
    font-size: 1rem;padding-top:.5rem;padding-left:1rem
  };
  @media (min-width:768px){
    padding-top:2rem;font-size: 1.2rem
  }
  @media (min-width:1200px){
    font-size: 2rem;
    padding-top:2.5rem
  }
`
const ImgBoxSub = styled(ImgBoxText)`
  font-size:.5rem;text-transform:none;
  @media (min-width:640px){
    font-size: .65rem
  };
  @media (min-width:768px){
    font-size: .8rem
  }
  @media (min-width:1200px){
    font-size: 1rem
  }
`
const JumboImgSm = styled.img`
  display:none !important;
  width:100%;height:100%;object-fit:cover;
  @media (max-width:768px){
    display:flex !important;
  }
`
const JumboImgLg = styled.img`
  display:none !important;
  width:100%;height:100%;object-fit:cover;
  @media (min-width:769px) and (max-width:1366px){
    display:flex !important;
  }
`
const JumboImgXl = styled.img`
  display:none !important;
  width:100%;height:100%;object-fit:cover;
  @media (min-width:1367px){
    display:flex !important;
  }
`
export default () => {
  var settings = {
    infinite: true,
    speed: 500,
    prevArrow: <ArrowSlide to="left" />,
    nextArrow: <ArrowSlide to="right" />,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:5000,
    lazyLoad: true
  };
  const data = useStaticQuery(
    graphql`
      query {
        settings{
          data{
            banners{
              title,url,imgs{
                lg,sm,xl
              }
            }
          }
        }
      }
    `
  )
  return (
    <Slider {...settings}>
      {data.settings.data.banners[0].title!='-' ? data.settings.data.banners.map((banner,idx)=>
        <JumbotronContainer key={banner.title}>
          <BannerLink href={banner.url}>
            <JumboImgSm src={banner.imgs.sm} alt={"bgimage small " + banner.title}/>
            <JumboImgLg src={banner.imgs.lg} alt={"bgimage large " + banner.title}/>
            <JumboImgXl src={banner.imgs.xl} alt={"bgimage x-large " + banner.title}/>
          </BannerLink>
        </JumbotronContainer>
      ):''}
    </Slider>
    
  )
}


