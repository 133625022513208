
import React, { Component } from 'react';
import styled from 'react-emotion';
import dotenv from 'dotenv'
import { connect } from 'react-redux';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import ReactPixel from 'react-facebook-pixel'; 
import Layout from '../components/layout';
import Jumbotron from '../components/home/jumbotron';
import Testimony from '../components/home/testimony';
import FeaturedProducts from '../components/home/featured-products';
import Popular from '../components/home/popular';
import Bclients from '../components/home/bclients';
import NewsFeat from '../components/home/news'

// import FindUs from '../components/home/find-us';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./home-slick.css";

const ContainerFlex = styled.div
  `${tw`w-full container px-4 sm:px-8 lg:px-12`}`

const Feature = styled.div`
  ${tw`relative text-white w-full h-full shadow-md mt-8 md:mx-3 px-3 pb-4 mx-1`};
  background-color: #8520C5;
  padding-top: 1.5rem;
  border-radius: 1.25rem;
`;
const FeatureContainer = styled.div`
  ${tw`h-full pr-2 sm:pr-6`}
`
const RoundedSymbol = styled.div`
  ${tw`bg-red w-10 h-10 absolute `};
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`

const FeatureHeader = styled.div`
  ${tw`text-center font-bold uppercase mb-3 leading-none text-sm`}
`

const FeatureContent = styled.div`
  ${tw`text-center font-normal md:text-sm leading-tight`}
  font-size:.68rem
`
const LeadTitle = styled.h2
  `${tw`text-center my-2 uppercase text-lg md:text-xl`}
  font-family: Open sans
`
const PageTitle = styled.h1`
  ${tw`invisible absolute`}
`
const featureList = [
  {
    header: 'Mudah',
    content: 'Mudah ordernya, mudah pakainya & mudah pengembaliannya',
    symbol: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M466.27 286.69C475.04 271.84 480 256 480 236.85c0-44.015-37.218-85.58-85.82-85.58H357.7c4.92-12.81 8.85-28.13 8.85-46.54C366.55 31.936 328.86 0 271.28 0c-61.607 0-58.093 94.933-71.76 108.6-22.747 22.747-49.615 66.447-68.76 83.4H32c-17.673 0-32 14.327-32 32v240c0 17.673 14.327 32 32 32h64c14.893 0 27.408-10.174 30.978-23.95 44.509 1.001 75.06 39.94 177.802 39.94 7.22 0 15.22.01 22.22.01 77.117 0 111.986-39.423 112.94-95.33 13.319-18.425 20.299-43.122 17.34-66.99 9.854-18.452 13.664-40.343 8.99-62.99zm-61.75 53.83c12.56 21.13 1.26 49.41-13.94 57.57 7.7 48.78-17.608 65.9-53.12 65.9h-37.82c-71.639 0-118.029-37.82-171.64-37.82V240h10.92c28.36 0 67.98-70.89 94.54-97.46 28.36-28.36 18.91-75.63 37.82-94.54 47.27 0 47.27 32.98 47.27 56.73 0 39.17-28.36 56.72-28.36 94.54h103.99c21.11 0 37.73 18.91 37.82 37.82.09 18.9-12.82 37.81-22.27 37.81 13.489 14.555 16.371 45.236-5.21 65.62zM88 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"></path></svg>)
  }, {
    header: 'Hemat',
    content: 'Harga terbaik dan bisa dibagi berlima & Pay as you go',
    symbol: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="white" d="M621.16 54.46C582.37 38.19 543.55 32 504.75 32c-123.17-.01-246.33 62.34-369.5 62.34-30.89 0-61.76-3.92-92.65-13.72-3.47-1.1-6.95-1.62-10.35-1.62C15.04 79 0 92.32 0 110.81v317.26c0 12.63 7.23 24.6 18.84 29.46C57.63 473.81 96.45 480 135.25 480c123.17 0 246.34-62.35 369.51-62.35 30.89 0 61.76 3.92 92.65 13.72 3.47 1.1 6.95 1.62 10.35 1.62 17.21 0 32.25-13.32 32.25-31.81V83.93c-.01-12.64-7.24-24.6-18.85-29.47zM48 132.22c20.12 5.04 41.12 7.57 62.72 8.93C104.84 170.54 79 192.69 48 192.69v-60.47zm0 285v-47.78c34.37 0 62.18 27.27 63.71 61.4-22.53-1.81-43.59-6.31-63.71-13.62zM320 352c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 27.78c-17.52-4.39-35.71-6.85-54.32-8.44 5.87-26.08 27.5-45.88 54.32-49.28v57.72zm0-236.11c-30.89-3.91-54.86-29.7-55.81-61.55 19.54 2.17 38.09 6.23 55.81 12.66v48.89z"></path></svg>)
  }, {
    header: 'Cepat',
    content: 'Kecepatan up to 100 Mbps, kuota unlimited & cepat ordernya',
    symbol: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M505.1 19.1C503.8 13 499 8.2 492.9 6.9 460.7 0 435.5 0 410.4 0 307.2 0 245.3 55.2 199.1 128H94.9c-18.2 0-34.8 10.3-42.9 26.5L2.6 253.3c-8 16 3.6 34.7 21.5 34.7h95.1c-5.9 12.8-11.9 25.5-18 37.7-3.1 6.2-1.9 13.6 3 18.5l63.6 63.6c4.9 4.9 12.3 6.1 18.5 3 12.2-6.1 24.9-12 37.7-17.9V488c0 17.8 18.8 29.4 34.7 21.5l98.7-49.4c16.3-8.1 26.5-24.8 26.5-42.9V312.8c72.6-46.3 128-108.4 128-211.1.1-25.2.1-50.4-6.8-82.6zM400 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"></path></svg>)
  }, {
    header: 'Stabil',
    content: 'Jaringan internet stabil & Technical support 24 jam / 7 hari',
    symbol: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="white" d="M634.91 154.88C457.74-8.99 182.19-8.93 5.09 154.88c-6.66 6.16-6.79 16.59-.35 22.98l34.24 33.97c6.14 6.1 16.02 6.23 22.4.38 145.92-133.68 371.3-133.71 517.25 0 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.97c6.43-6.39 6.3-16.82-.36-22.98zM320 352c-35.35 0-64 28.65-64 64s28.65 64 64 64 64-28.65 64-64-28.65-64-64-64zm202.67-83.59c-115.26-101.93-290.21-101.82-405.34 0-6.9 6.1-7.12 16.69-.57 23.15l34.44 33.99c6 5.92 15.66 6.32 22.05.8 83.95-72.57 209.74-72.41 293.49 0 6.39 5.52 16.05 5.13 22.05-.8l34.44-33.99c6.56-6.46 6.33-17.06-.56-23.15z"></path></svg>)
  }, {
    header: 'Shareable',
    content: 'Wifi dapat digunakan hingga 5 gadget secara bersamaan',
    symbol: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="white" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg>)
  }, {
    header: 'Jaringan Luas',
    content: 'Jaringan Wifi Republic terkoneksi di lebih dari 130 negara.',
    symbol: (<svg viewBox="0 0 55 55"><path fill="white" d="M49,0c-3.309,0-6,2.691-6,6c0,1.035,0.263,2.009,0.726,2.86l-9.829,9.829C32.542,17.634,30.846,17,29,17 s-3.542,0.634-4.898,1.688l-7.669-7.669C16.785,10.424,17,9.74,17,9c0-2.206-1.794-4-4-4S9,6.794,9,9s1.794,4,4,4 c0.74,0,1.424-0.215,2.019-0.567l7.669,7.669C21.634,21.458,21,23.154,21,25s0.634,3.542,1.688,4.897L10.024,42.562 C8.958,41.595,7.549,41,6,41c-3.309,0-6,2.691-6,6s2.691,6,6,6s6-2.691,6-6c0-1.035-0.263-2.009-0.726-2.86l12.829-12.829 c1.106,0.86,2.44,1.436,3.898,1.619v10.16c-2.833,0.478-5,2.942-5,5.91c0,3.309,2.691,6,6,6s6-2.691,6-6c0-2.967-2.167-5.431-5-5.91 v-10.16c1.458-0.183,2.792-0.759,3.898-1.619l7.669,7.669C41.215,39.576,41,40.26,41,41c0,2.206,1.794,4,4,4s4-1.794,4-4 s-1.794-4-4-4c-0.74,0-1.424,0.215-2.019,0.567l-7.669-7.669C36.366,28.542,37,26.846,37,25s-0.634-3.542-1.688-4.897l9.665-9.665 C46.042,11.405,47.451,12,49,12c3.309,0,6-2.691,6-6S52.309,0,49,0z M11,9c0-1.103,0.897-2,2-2s2,0.897,2,2s-0.897,2-2,2 S11,10.103,11,9z M6,51c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S8.206,51,6,51z M33,49c0,2.206-1.794,4-4,4s-4-1.794-4-4 s1.794-4,4-4S33,46.794,33,49z M29,31c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S32.309,31,29,31z M47,41c0,1.103-0.897,2-2,2 s-2-0.897-2-2s0.897-2,2-2S47,39.897,47,41z M49,10c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S51.206,10,49,10z"/></svg>)
  }
]

const FeatureList = featureList.map((feature,idd) => (
  <FeatureContainer key={'featC_'+idd}>
    <Feature>
      <RoundedSymbol>
        <div style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}>
          <div style={{
            width: '50%',
            height: '50%',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
            {feature.symbol}
          </div>
        </div>
      </RoundedSymbol>
      <FeatureHeader>
        {feature.header}
      </FeatureHeader>
      <FeatureContent>
        {feature.content}
      </FeatureContent>
    </Feature>
  </FeatureContainer>
));

dotenv.config();

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,siteUrl,description
      }
    }
  }
`

class IndexPage extends Component {
  componentDidMount() {
    const referralCode = this.props.location ? queryString.parse(this.props.location.search).ref_id : '';
    if (referralCode) {
      this.props.changeReferralCode(referralCode);
    }

    ReactPixel.init(process.env.GATSBY_PIXEL_ID)
    ReactPixel.pageView()
    if (this.props.showProductToast) {
      toast.info('Produk telah ditambah ke dalam keranjang belanja', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      this.props.delToast();
    }
  }

  render() {
    var slidesettings = {
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed:2000,
      slidesToShow: 4,
      className: "featlist",
      responsive: [
        {
          breakpoint: 815,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };
    
    return (
      <Layout>
        <Helmet>
          <title>{this.props.data.site.siteMetadata.title}</title>
          <link rel="alternate" href={this.props.data.site.siteMetadata.siteUrl} hrefLang="id" />
          <link rel="canonical" href={this.props.data.site.siteMetadata.siteUrl} />
          <meta name="title" content={this.props.data.site.siteMetadata.title} />
          <meta name="description" content={this.props.data.site.siteMetadata.description} />
          <meta name="thumbnail" content="https://api.wifirepublicid.com/images/logo.png" />
          <meta name="og:title" content={this.props.data.site.siteMetadata.title} />
          <meta name="og:type" content="website" />
          <meta name="og:description" content={this.props.data.site.siteMetadata.description} />
          <meta name="og:image" content="https://api.wifirepublicid.com/images/logo.png" />
          <meta name="og:url" content={this.props.data.site.siteMetadata.siteUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={this.props.data.site.siteMetadata.title} />
          <meta name="twitter:description" content={this.props.data.site.siteMetadata.description} />
          <meta name="twitter:image" content="https://api.wifirepublicid.com/images/logo.png" />
          <meta name="twitter:url" content={this.props.data.site.siteMetadata.siteUrl} />
          <meta name="twitter:site" content="@wifirepublic" />
          <meta name="google-site-verification" content="CRcTHYaornKxMkk_512NrJdLnZcc_yQPADrGT0LcyT4" />
          <script src="/jquery.min.js" />
          <script src="/theme.js" />
          <link rel="stylesheet" href="/theme2.css" />
          <link rel="stylesheet" href="/gilroy.css" />
        </Helmet>
        <Jumbotron />
        <PageTitle>{this.props.data.site.siteMetadata.title}</PageTitle>
        <ContainerFlex>
          <LeadTitle>Kenapa Rental/Sewa wifi di Wifi Republic</LeadTitle>
          <Slider {...slidesettings}>
            {FeatureList}
          </Slider>
        </ContainerFlex>
        <FeaturedProducts />
        <NewsFeat />
        <Testimony />
        <Bclients />
        <Popular />
        {/* <FindUs /> */}
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    showProductToast: state.showProductToast
  }
}

const mapDispatchToProps = dispatch => {
  return {
    delToast: () => dispatch({ type: `DEL_TOAST` }),
    changeReferralCode: (code) => dispatch({ type: 'CHANGE_REFERRAL_CODE', code })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);