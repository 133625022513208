import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby'
import styled from 'react-emotion';
import Container from '../container';
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import 'rc-tabs/assets/index.css';
import './tabs.css'

const Background = styled.div`
  
  background-color: #FFF
`

const Heading = styled.h2`
  ${tw`text-lg font-semibold pt-6 mb-3 text-center md:text-xl lg:text-2xl`}
  font-family: Open Sans;
`
const SubHeading = styled.h3`
  ${tw`text-sm px-4 mb-3 leading-tight font-medium text-center md:text-lg lg:text-xl`}
  font-family: Open Sans;
`

const ContactContainer = styled.div`
  ${tw`w-full container py-3 md:flex md:flex-wrap md:justify-center`}
`
const GridContainer = styled.div`
  ${tw`w-full flex flex-wrap`}
`
const LinkTo = styled.a`
  ${tw`w-full truncate mb-1 text-xs sm:w-1/3 sm:mb-3 sm:px-2 lg:w-1/3`};
  color:initial;
  text-decoration:none;
`

class Popular extends Component {
  
  onChange = (key) => {
    console.log(`onChange ${key}`);
  }
  onTabClick = (key) => {
    console.log(`onTabClick ${key}`);
  }
  render() {
    return (
      <Background>
        <Container>
          <Heading>Apa Yang menarik minat anda?</Heading>
          <ContactContainer>
            <StaticQuery
              query={graphql`
              query {
                allPopularInternetLuarNegeri {
                  edges {
                    node {
                      title,category,slug
                    }
                  }
                }
                allPopularInternetIndonesia {
                  edges {
                    node {
                      title,category,slug
                    }
                  }
                }
                allPopularReseller {
                  edges {
                    node {
                      title,category,slug
                    }
                  }
                }
              }`}
              render={data=>
                <Tabs defaultActiveKey="1" onChange={this.onChange} renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
                  <TabPane tab="Internet Luar Negeri Terpopuler" key="1">
                    <GridContainer>
                      {data.allPopularInternetLuarNegeri.edges.map((item,idx)=>(
                      <LinkTo key={'wifipop'+idx} href={item.node.slug}>{item.node.title}</LinkTo>))}
                    </GridContainer>
                  </TabPane>
                  <TabPane tab="Internet Indonesia Terpopuler" key="2">
                    <GridContainer>
                      {data.allPopularInternetIndonesia.edges.map((item,idx)=>(
                      <LinkTo key={'wifipop'+idx} href={item.node.slug}>{item.node.title}</LinkTo>))}
                    </GridContainer>
                  </TabPane>
                  {/* <TabPane tab="Sim Card Terpopuler" key="2">
                    <GridContainer>
                      {data.allPopularSimcard.edges.map((item,idx)=>(
                      <LinkTo key={'simpop'+idx} href={item.node.slug}>{item.node.title}</LinkTo>))}
                    </GridContainer>
                  </TabPane> */}
                  <TabPane tab="Produk Reseller Terpopuler" key="3">
                    <GridContainer>
                      {data.allPopularReseller.edges.map((item,idx)=>(
                      <LinkTo key={'tiketpop'+idx} href={item.node.slug}>{item.node.title}</LinkTo>))}
                    </GridContainer>
                  </TabPane>
                </Tabs>
              }
            />
          </ContactContainer>
          
        </Container>
      </Background>
    );
  }
}

export default Popular;