import React from 'react';
import styled from 'react-emotion';
import Slider from 'react-slick'
import nws1 from '../../../static/images/news/01.png'
import nws2 from '../../../static/images/news/02.png'
import nws3 from '../../../static/images/news/03.png'
import nws4 from '../../../static/images/news/04.png'
import nws5 from '../../../static/images/news/05.png'
import nws6 from '../../../static/images/news/06.png'

import './bclient.css'

const Background = styled.div`
  background-color: #fff;
  padding-bottom: 80px;
`
const Container = styled.div`
  ${tw`container mx-auto pt-4 px-12 w-full`};
  @media (min-width: 567px) {
    max-width: 576px;
  }
  @media (min-width: 768px) {
    max-width: 800px;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;
const Heading = styled.h2`
  ${tw`uppercase text-xl font-bold pt-6 mb-3 text-center md:text-2xl lg:text-3xl`}
  font-family: Open Sans;
`
const CardSlide = styled.div`
  padding: 10px 15px;
`
const CardHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const CardHeadIns = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`
const CardHeadTxt = styled.a`
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  font-size: .85rem;
  font-weight: 600;
`
const CardStar = styled.div`
  display: flex;
`
const ImgNws = styled.img`
  height: 47px;
  width: 47px;
  margin: auto 0;
  border-radius: 50%;
`
const CardText = styled.p`
  font-size: .75rem;
  line-height: 150%;
`
const StarRate = () => (
    <svg width="14px" height="14px" style={{marginRight:'2px'}}>
        <path style={{fill:"#ffdd39"}} d="M14,5.4c-0.1-0.2-0.3-0.4-0.5-0.4L9.4,4.4L7.6,0.7C7.5,0.4,7.2,0.3,7,0.3c-0.2,0-0.5,0.1-0.6,0.4L4.6,4.4L0.5,4.9
        C0.3,5,0.1,5.1,0,5.4C0,5.6,0,5.9,0.2,6l2.9,2.9l-0.7,4c0,0.2,0.1,0.5,0.3,0.6c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.3-0.1L7,11.7
        l3.6,1.9c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.4,0,0.6-0.3,0.6-0.6c0,0,0-0.1,0-0.1l-0.7-4L13.8,6C14,5.9,14,5.6,14,5.4z"></path>
    </svg>
)
const nwsfeats = [
  {
    alt: 'Tribun News',
    picture: nws1,
    url: 'https://www.tribunnews.com/',
    text: "Perusahaan yang memiliki kantor pusat di daerah Kuningan Jakarta Selatan ini telah melebarkan sayap ke kota – kota lain seperti Surabaya, Semarang, Bekasi, Depok, Bandung, Batam, Medan, Bali, Lombok, dan segera akan dibuka ke kota-kota lain di Indonesia",
  },{
    alt: 'Berita Satu',
    picture: nws2,
    url: 'https://www.beritasatu.com/',
    text: "Bagi kalangan yang masih WFH dan memerlukan jaringan internet, ada satu yang baru untuk memenuhi kebutuhan internet sekarang ini namanya Wifi Republic",
  },{
    alt: 'The Jakarta Post',
    picture: nws3,
    url: 'https://www.thejakartapost.com/',
    text: "In order to grow, wifi republic said that it would expand its network more ad make sure to provide customers with good connections",
  },{
    alt: 'Kontan',
    picture: nws4,
    url: 'https://www.kontan.co.id/',
    text: "Kenaikan pelanggan WFH terjadi dua kali lipat dibandingkan tahun lalu. Sebagian besar peningkatan permintaan dari existing pelanggan, karena perusahaan yang menganggap servis kami meningkatkan kinerja karyawan untuk WFH",
  },{
    alt: 'Bisnis.com',
    picture: nws5,
    url: 'https://www.bisnis.com/',
    text: "Wifi Republic beroperasi di kota-kota besar di seluruh Indonesia seperti Jakarta, Bekasi, Tangerang Selatan, Surabaya, Bali, Depok, Batam, Medan, Semarang, Bogor, dan Depok",
  },{
    alt: 'Liputan6.com',
    picture: nws6,
    url: 'https://www.liputan6.com/',
    text: "Kecepatan koneksi internetnya diklaim bisa mencapai 25 mbps yang bisa digunakan di lebih dari 150 negara dan juga menawarkan kuota unlimited." 
  },
]

const NewsFeat = () => {
  var nwsSettings = {
    slidesToShow: 3,
    dots:true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <Background>
      <Heading>AS FEATURED IN</Heading>
      <Container>
        <Slider className={'news-feat'} {...nwsSettings}>
          {
            nwsfeats.map((nw,idx) => (
              <CardSlide>
                <CardHead>
                  <ImgNws src={nw.picture} alt={nw.alt} />
                  <CardHeadIns>
                    <CardHeadTxt href={nw.url}>{nw.alt}</CardHeadTxt>
                    <CardStar>
                      <StarRate /><StarRate /><StarRate /><StarRate /><StarRate />
                    </CardStar>
                  </CardHeadIns>
                </CardHead>
                <CardText>{nw.text}</CardText>
              </CardSlide>
            ))
          }
        </Slider>
      </Container>
    </Background>
  )
}

export default NewsFeat;