import React from 'react';
import styled from 'react-emotion';
import Slider from 'react-slick'
import clt1 from '../../../static/images/bclients/bank danamon.png'
import clt2 from '../../../static/images/bclients/denso.png'
import clt3 from '../../../static/images/bclients/deutsche.png'
import clt4 from '../../../static/images/bclients/halodoc.png'
import clt5 from '../../../static/images/bclients/hitachi.png'
import clt6 from '../../../static/images/bclients/hyundai.png'
import clt7 from '../../../static/images/bclients/jti logo.png'
import clt8 from '../../../static/images/bclients/mapfre.png'
import './bclient.css'

const Background = styled.div`
  background-color: #161616;
  padding-bottom: 80px;
`
const Container = styled.div`
  ${tw`container mx-auto pt-4 px-12 w-full`};
  @media (min-width: 567px) {
    max-width: 576px;
  }
  @media (min-width: 768px) {
    max-width: 800px;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;
const Heading = styled.h2`
  ${tw`uppercase text-xl font-bold pt-6 mb-3 text-white text-center md:text-2xl lg:text-3xl`}
  font-family: Open Sans;
`
const SubHeading = styled.h3`
  ${tw`text-sm px-4 mb-3 leading-tight font-medium text-white text-center md:text-sm lg:text-md`}
  font-family: Open Sans;
`
const SVGIcon = styled.svg`
  ${tw`fill-current h-5 w-5 md:h-10 md:w-10`};
`
const ImgDiv = styled.div`
  height: 50px;
`
const ImgClt = styled.img`
  height: 100%;
  margin: auto;
`
const ArrowBtn = styled.button`
  color: #fff;
  z-index:1;
  &:before{
    content: initial
  };
  &:hover,&:focus{
    color:#eee
  }
`
const LeftArrow = styled(ArrowBtn)`
  left:-50px;
  opacity:.7;
`
const RightArrow = styled(ArrowBtn)`
  right:-30px;
  opacity:.7;
`
const ArrowSlide = ({className,to,onClick})=>(
  to=='left' ? 
    <LeftArrow type="button" onClick={onClick} className={`arrow-btn ${className}`} aria-label={to}>
      <SVGIcon viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M 55.921875 9.867188 L 62.5 9.867188 C 63.773438 9.867188 64.929688 10.601563 65.46875 11.746094 C 66.019531 12.902344 65.855469 14.261719 65.042969 15.238281 L 37.148438 49.34375 L 65.042969 83.445313 C 65.855469 84.421875 66.019531 85.785156 65.46875 86.941406 C 64.929688 88.082031 63.773438 88.816406 62.5 88.816406 L 55.921875 88.816406 C 54.933594 88.816406 53.992188 88.367188 53.375 87.609375 L 23.773438 51.421875 C 22.78125 50.214844 22.78125 48.46875 23.773438 47.261719 L 53.375 11.078125 C 53.992188 10.316406 54.933594 9.867188 55.921875 9.867188 Z "/>
      </SVGIcon>
    </LeftArrow> : 
    <RightArrow type="button" onClick={onClick} className={`arrow-btn ${className}`} aria-label={to}>
      <SVGIcon viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M 39.472656 88.816406 L 32.894531 88.816406 C 31.621094 88.816406 30.464844 88.082031 29.925781 86.941406 C 29.375 85.785156 29.542969 84.421875 30.351563 83.445313 L 58.246094 49.34375 L 30.351563 15.238281 C 29.542969 14.261719 29.375 12.902344 29.925781 11.746094 C 30.464844 10.601563 31.621094 9.867188 32.894531 9.867188 L 39.472656 9.867188 C 40.464844 9.867188 41.402344 10.316406 42.019531 11.078125 L 71.625 47.261719 C 72.613281 48.46875 72.613281 50.214844 71.625 51.421875 L 42.019531 87.609375 C 41.402344 88.367188 40.464844 88.816406 39.472656 88.816406 Z "/>
      </SVGIcon>
    </RightArrow>
)
const clients = [
  {
    alt: 'Bank Danamon',
    picture: clt1
  },{
    alt: 'Denso',
    picture: clt2
  },{
    alt: 'Deutsche',
    picture: clt3
  },{
    alt: 'HaloDoc',
    picture: clt4
  },{
    alt: 'Hitachi',
    picture: clt5
  },{
    alt: 'Hyundai',
    picture: clt6
  },{
    alt: 'JTI',
    picture: clt7
  },{
    alt: 'MapFre',
    picture: clt8
  },
]

const Bclients = () => {
  var cltSettings = {
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    dots:true,
    prevArrow: <ArrowSlide to="left" />,
    nextArrow: <ArrowSlide to="right" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 3,
          centerPadding:'40px',
          arrows:true,
        }
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          centerPadding:'40px',
          arrows:false,
        }
      }
    ]
  };
  return (
    <Background>
      <Heading>Trusted by BIG NAMES</Heading>
      <SubHeading>Wifi Republic dipercaya oleh banyak perusahaan besar bersakala Nasional dan Internasional untuk mendapatkan koneksi Internet yang handal dan berkualitas.</SubHeading>
      <Container>
        <Slider className="dscn" {...cltSettings}>
          {
            clients.map((clt,idx) => (
              <ImgDiv key={"bclt-"+idx}>
                <ImgClt src={clt.picture} alt={clt.alt} />
              </ImgDiv>
            ))
          }
        </Slider>
      </Container>
    </Background>
  )
}

export default Bclients;