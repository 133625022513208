import React from 'react';
import { Link } from 'gatsby';
import styled from 'react-emotion';
import Slider from 'react-slick'
import testi1 from '../../../static/images/testimonies/testi1.jpg'
import testi2 from '../../../static/images/testimonies/testi2.jpg'
import testi3 from '../../../static/images/testimonies/testi3.jpg'
import testi4 from '../../../static/images/testimonies/testi4.jpg'
import testi5 from '../../../static/images/testimonies/testi5.jpg'
import testi6 from '../../../static/images/testimonies/testi6.jpg'

import Container from '../container';

const Background = styled.div`
  ${tw`bg-grey-light shadow`};
`
const TestiText = styled.p`
  ${tw`text-xs leading-tight text-center`}
`
const TestimonyBox = styled.div`
  ${tw`p-2 md:p-5 flex flex-col justify-center items-center`}
  margin-bottom: 10px;
`
const Heading = styled.h2`
  ${tw`uppercase text-xl font-bold pt-6 mb-3 text-center md:text-2xl lg:text-3xl`}
  font-family: Open Sans;
`
const SubHeading = styled.h3`
  ${tw`text-sm px-4 mb-3 leading-tight font-medium text-center md:text-lg lg:text-xl`}
  font-family: Open Sans;
`
const ProfilePicture = styled.img`
  ${tw`w-full mb-2`}
  background-size: cover;
`

const ProfileName = styled.h4`
  ${tw`text-center my-2`}
  font-family:Open Sans
`

const testimonies = [
  {
    name: 'Anang & Ashanty',
    alt: 'Gambar Anang & Ashanty',
    testimonial: 'Traveling ke luar negeri pakai TravelWifi koneksi tetap lancar. Kita udah pakai TravelWifi, kalau kamu?',
    picture: testi1
  },{
    name: 'Hotman Paris',
    alt: 'Gambar Hotman Paris',
    testimonial: 'Internet lancar dimanapun sangat direkomendasikan untuk kegiatan WFH dengan TravelWifi.',
    picture: testi2
  },{
    name: 'Soraya Larasati',
    alt: 'Gambar Soraya Larasati',
    testimonial: 'Work From Anywhere selalu pakai TravelWifi, dimanapun koneksi aman dan lancar.',
    picture: testi3
  },{
    name: 'Sylvia Genpati',
    alt: 'Gambar Sylvia Genpati',
    testimonial: 'Kemanapun traveling selalu bawa TravelWifi, sinyalnya kenceng di benua manapun',
    picture: testi4
  },{
    name: 'Shannon Dorothea',
    alt: 'Gambar Shannon Dorothea',
    testimonial: 'Buat WFH dirumah oke, ditempat umum juga oke, karena TravelWifi bisa dibawa kemana-mana.',
    picture: testi5
  },
]

const Testimony = () => {
  var testiSettings = {
    infinite: true,
    speed: 300,
    arrows:false,
    autoplay: true,
    autoplaySpeed:5000,
    slidesToShow:4,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  return (
    <Background>
      <Heading>Testimoni Wifi Republic</Heading>
      <SubHeading>Untuk kamu, kemudahan internet tanpa batas!</SubHeading>
      <Container>
        <Slider {...testiSettings}>
          {
            testimonies.map((testimony,idx) => (
              <TestimonyBox key={'testi-'+idx}>
                <ProfilePicture src={testimony.picture} alt={testimony.alt} />
                <ProfileName> {testimony.name} </ProfileName>
                <TestiText> {testimony.testimonial} </TestiText>
              </TestimonyBox>
            ))
          }
        </Slider>
      </Container>
    </Background>
  )
}

export default Testimony;